import { Footer as FlowbiteFooter } from 'flowbite-react';
import { Link } from 'react-router-dom';
import { flowbiteCustomFooterThemeConfig } from '../config/flowbiteCustomThemeConfig';

function Footer() {
  return (
    <div id='page_bottom_container'>
      <FlowbiteFooter theme={flowbiteCustomFooterThemeConfig} container>
        <FlowbiteFooter.Copyright href='https://featheruniverse.com' by='Developed by Feather IT' year={new Date().getFullYear()} />
        <FlowbiteFooter.LinkGroup>
          <Link to='/privacy-policy'>
            <a className='hover:underline'>Privacy Policy</a>
          </Link>
        </FlowbiteFooter.LinkGroup>
      </FlowbiteFooter>
    </div>
  );
}

export default Footer;
