import { useContext } from 'react';

import { AppContext, IAppContextState } from '../App';

import Footer from '../shared/components/Footer';
import SidebarComponent from '../shared/components/Sidebar';

function MainLayout({ children }: any) {
  const { isOpenSidebarState, isCollapsedSidebarState } = useContext<IAppContextState | any>(AppContext);

  const [isOpenSidebar] = isOpenSidebarState;
  const [isCollapsedSidebar] = isCollapsedSidebarState;

  return (
    <>
      <SidebarComponent />
      <div
        id='quranrt_main_content_layout_container'
        className={`${isOpenSidebar ? 'lg:ml-[18rem]' : isCollapsedSidebar ? 'ml-0' : 'ml-[4.3rem]'} position-absolute lg:position-static dark:bg-gray-600 h-screen overflow-auto`}>
        <div className='flex flex-col w-full h-full justify-between'>
          <div className='px-4 pt-4 pb-2'>
            <div className='w-[100%] rounded-lg bg-white border border-gray-300 dark:bg-gray-700 dark:border-yellow-400 p-4'>{children}</div>
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
}

export default MainLayout;
