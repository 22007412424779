import { createBrowserRouter } from 'react-router-dom';

import Donate from '../modules/donate/components/Donate';
import BookList from '../modules/books/components/BookList';
import BookDetails from '../modules/books/components/BookDetail';
import SurahList from '../modules/home/components/SurahList';
import AyatDetails from '../modules/home/components/AyatDetails';
import Bookmark from '../modules/bookmark/components/Bookmark';
import SearchAyat from '../modules/home/components/SearchAyat';
import PrivacyPolicy from '../shared/components/PrivacyPolicy';
import Highlight from '../modules/highlight/components/Highlight';
import BookmarkDetails from '../modules/bookmark/components/BookmarkDetails';
import Note from '../modules/note/components/Note';

// Routes

export const router = createBrowserRouter([
  {
    path: '',
    children: [
      {
        path: '',
        element: <SurahList />,
      },
      {
        path: 'surah/:id',
        element: <AyatDetails />,
      },
    ],
  },
  {
    path: 'donate',
    element: <Donate />,
  },
  {
    path: 'highlight',
    element: <Highlight />,
  },
  {
    path: 'note',
    element: <Note />,
  },
  {
    path: 'search',
    element: <SearchAyat />,
  },
  {
    path: 'privacy-policy',
    element: <PrivacyPolicy />,
  },
  {
    path: 'books',
    children: [
      {
        path: '',
        element: <BookList />,
      },
      {
        path: ':id',
        element: <BookDetails />,
      },
    ],
  },
  {
    path: 'bookmarks',
    children: [
      {
        path: '',
        element: <Bookmark />,
      },
      {
        path: ':categoryId',
        element: <BookmarkDetails />,
      },
    ],
  },
]);
