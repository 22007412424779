import axios from 'axios';

import { BASE_URL } from '../../../environments/environment';

import { ISearchAyatPagination, ISurahDetails, ISurahPagination } from '../interface/surah.interface';
import { IHttpResponse } from '../../../shared/interface/http-response.interface';
import { IPaginationParams } from '../../../shared/interface/pagination.interface';

export class SurahService {
  async getAllSurah(params?: IPaginationParams): Promise<IHttpResponse<ISurahPagination>> {
    const { data } = await axios.get(`${BASE_URL}/surah`, { params });
    return data;
  }

  async getOneSurah(surahId: number, params?: IPaginationParams): Promise<IHttpResponse<ISurahDetails>> {
    const { data } = await axios.get(`${BASE_URL}/surah/${surahId}`, { params });
    return data;
  }

  async search(params?: IPaginationParams): Promise<IHttpResponse<ISearchAyatPagination>> {
    const { data } = await axios.get(`${BASE_URL}/ayat/search`, { params });
    return data;
  }
}
