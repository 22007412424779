import React, { createContext, useEffect, useState } from 'react';
import { RouterProvider } from 'react-router';
import { ToastContainer, toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import 'flowbite/dist/flowbite';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';

import { router } from './routes/routes';

import { ELanguageCode } from './shared/enum/language.enum';

import { StorageService } from './shared/service/storageService';
import { SurahService } from './modules/home/service/SurahService';

import { IBookPagination } from './modules/books/interface/book.interface';
import { ISurahPagination } from './modules/home/interface/surah.interface';
import { IBookmark } from './modules/bookmark/interface/bookmark.interface';
import { INote } from './modules/home/interface/note.interface';

export function darkToggle(isActiveDarkMode: boolean) {
  if (isActiveDarkMode) {
    document.documentElement.classList.add('dark');
  } else {
    document.documentElement.classList.remove('dark');
  }
}

export interface IAppContextState {
  isOpenSidebarState: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
  isActiveDarkModeState: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
  isCollapsedSidebarState: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
  isReadingModeState: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
  loadingSurahState: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
  langCodeState: [ELanguageCode, React.Dispatch<React.SetStateAction<ELanguageCode>>];
  bookState: [IBookPagination, React.Dispatch<React.SetStateAction<IBookPagination>>];
  surahState: [ISurahPagination, React.Dispatch<React.SetStateAction<ISurahPagination>>];
  versesState: [number[], React.Dispatch<React.SetStateAction<number[]>>];
  bookmarkState: [IBookmark[], React.Dispatch<React.SetStateAction<IBookmark[]>>];
  highlightState: [string[], React.Dispatch<React.SetStateAction<string[]>>];
  noteState: [INote[], React.Dispatch<React.SetStateAction<INote[]>>];
  addOrRemoveHighlight: (key: string) => void;
}

export const AppContext = createContext<IAppContextState | null>(null);

function App() {
  const { i18n } = useTranslation();

  const storageService = new StorageService();

  const [langCode, setLangCode] = useState(storageService.getLanguageCode() ?? ELanguageCode.ENGLISH);
  const [bookmarks, setBookmarks] = useState<IBookmark[]>(storageService.getBookmarks() ?? []);
  const [notes, setNotes] = useState<INote[]>(storageService.getNotes() ?? []);
  const [highlights, setHighlights] = useState<string[]>(storageService.getHighlights() ?? []);
  const [isOpenSidebar, setIsOpenSidebar] = useState(storageService.getIsOpenSidebar() ?? false);
  const [isCollapsedSidebar, setIsCollapsedSidebar] = useState(storageService.getIsCollapseSidebar() ?? false);
  const [isReadingMode, setIsReadingMode] = useState(storageService.getIsReadingMode() ?? false);
  const [isActiveDarkMode, setIsActiveDarkMode] = useState(storageService.getIsDarkModeActivated() ?? false);

  const [loadingSurah, setLoadingSurah] = useState(false);
  const [surah, setSurah] = useState<ISurahPagination>({
    surahs: [],
    total_counts: 0,
  });
  const [verses, setVerses] = useState<number[]>([]);
  const [books, setBooks] = useState<IBookPagination>({
    books: [],
    total_counts: 0,
  });

  const surahService = new SurahService();

  const getAllSurah = async () => {
    try {
      setLoadingSurah(true);
      const surahResponse = await surahService.getAllSurah({ lang_code: langCode });
      setSurah(
        surahResponse?.data ?? {
          surahs: [],
          total_counts: 0,
        }
      );

      setVerses(Array.from({ length: surahResponse?.data?.surahs?.[0]?.total_ayat }, (_, index) => index + 1) ?? []);
      setLoadingSurah(false);
    } catch (error) {
      setLoadingSurah(false);
      console.warn(error);
    }
  };

  const addOrRemoveHighlight = (word: string) => {
    let localHighlight = storageService.getHighlights();

    const isExist = localHighlight.find((highlight: string) => highlight === word);

    if (isExist) {
      const index = localHighlight.indexOf(word);
      localHighlight.splice(index, 1);
      storageService.setHighlights(localHighlight);
      setHighlights(localHighlight);
      toast.error(`${word} successfully removed from highlight`);
    } else {
      localHighlight.push(word);
      storageService.setHighlights(localHighlight);
      setHighlights((prevHighlight: string[]) => [...prevHighlight, word]);
      toast.success(`${word} successfully highlighted`);
    }
  };

  useEffect(() => {
    darkToggle(isActiveDarkMode);
  }, []);

  useEffect(() => {
    getAllSurah();
    i18n.changeLanguage(langCode);
    return () => {
      setSurah({
        surahs: [],
        total_counts: 0,
      });
    };
  }, [langCode]);

  return (
    <>
      <ToastContainer position='bottom-center' autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme='colored' />
      <AppContext.Provider
        value={{
          isReadingModeState: [isReadingMode, setIsReadingMode],
          isOpenSidebarState: [isOpenSidebar, setIsOpenSidebar],
          isCollapsedSidebarState: [isCollapsedSidebar, setIsCollapsedSidebar],
          langCodeState: [langCode, setLangCode],
          isActiveDarkModeState: [isActiveDarkMode, setIsActiveDarkMode],
          loadingSurahState: [loadingSurah, setLoadingSurah],
          bookState: [books, setBooks],
          surahState: [surah, setSurah],
          versesState: [verses, setVerses],
          bookmarkState: [bookmarks, setBookmarks],
          highlightState: [highlights, setHighlights],
          noteState: [notes, setNotes],
          addOrRemoveHighlight,
        }}>
        <RouterProvider router={router} />
      </AppContext.Provider>
    </>
  );
}

export default App;
