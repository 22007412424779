import axios from 'axios';

import { BASE_URL } from '../../../environments/environment';

import { IBook, IBookPagination } from '../interface/book.interface';
import { IHttpResponse } from '../../../shared/interface/http-response.interface';
import { IPaginationParams } from '../../../shared/interface/pagination.interface';

export class BookService {
  async getAll(params?: IPaginationParams): Promise<IHttpResponse<IBookPagination>> {
    const { data } = await axios.get(`${BASE_URL}/books`, { params });
    return data;
  }

  async getAllPopular(params?: IPaginationParams): Promise<IHttpResponse<IBookPagination>> {
    const { data } = await axios.get(`${BASE_URL}/books/popular`, { params });
    return data;
  }

  async getAllRecent(params?: IPaginationParams): Promise<IHttpResponse<IBookPagination>> {
    const { data } = await axios.get(`${BASE_URL}/books/recent`, { params });
    return data;
  }

  async getOne(id: number): Promise<IHttpResponse<IBook>> {
    const { data } = await axios.get(`${BASE_URL}/books/${id}`);
    return data;
  }
}
