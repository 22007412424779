import { useContext, useEffect, useState } from 'react';
import { Modal } from 'flowbite-react';
import { useTranslation } from 'react-i18next';

import { AppContext, IAppContextState } from '../../../App';

import { IAyat } from '../../home/interface/surah.interface';
import { IBookmark } from '../interface/bookmark.interface';

import { StorageService } from '../../../shared/service/storageService';

interface IAddToBookmarkModalProps {
  markedBookmarkAyat: IAyat | null;
  state: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
  clickAction: (collectionId: string) => void;
}

function AddToBookmarkModal({ state, clickAction }: IAddToBookmarkModalProps) {
  const { t } = useTranslation();
  const { bookmarkState } = useContext<IAppContextState | any>(AppContext);
  const [bookmarks] = bookmarkState;

  const [searchedBookmarks, setSearchedBookmarks] = useState<IBookmark[]>(bookmarks);

  const [openModal, setOpenModal] = state;

  const storageService = new StorageService();

  const handleBookmarkSearch = (searchText: string) => {
    const text = searchText.toLocaleLowerCase();
    if (searchText === '') {
      setSearchedBookmarks(storageService.getBookmarks() ?? []);
    } else {
      const afterSearch = bookmarks.filter((bookmark: IBookmark) => bookmark?.title?.toLocaleLowerCase().includes(text));
      setSearchedBookmarks(afterSearch);
    }
  };

  useEffect(() => {
    setSearchedBookmarks(bookmarks);

    return () => {
      setSearchedBookmarks([]);
    };
  }, [bookmarks.length]);

  return (
    <Modal show={openModal} size='lg' onClose={() => setOpenModal(false)} popup>
      <Modal.Header>
        <h4 className='p-3'>{t('bookmark.title')}</h4>
      </Modal.Header>
      <Modal.Body>
        <div className='mt-2 mb-4 px-2'>
          <label htmlFor='search' className='mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white'>
            {t('placeholder.searchBookmark')}
          </label>
          <div className='relative'>
            <div className='absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none'>
              <svg className='w-4 h-4 text-gray-500 dark:text-gray-400' aria-hidden='true' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'>
                <path stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z' />
              </svg>
            </div>
            <input
              onChange={(e) => handleBookmarkSearch(e.target.value)}
              type='search'
              id='add_bookmark_search_input'
              className='block w-full p-4 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-yellow-400 focus:border-yellow-400 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-yellow-500 dark:focus:border-yellow-500'
              placeholder={t('placeholder.searchBookmark')}
              required
            />
            <button
              type='button'
              className='text-white absolute end-2.5 bottom-2.5 bg-yellow-400 hover:bg-yellow-500 focus:ring-4 focus:outline-none focus:ring-yellow-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-yellow-600 dark:hover:bg-yellow-700 dark:focus:ring-yellow-800'>
              {t('action.search')}
            </button>
          </div>
        </div>
        <div className='text-center'>
          {searchedBookmarks?.length ? (
            <div className='surah-container flex flex-wrap'>
              {bookmarks.map((item: IBookmark, key: number) => (
                <div onClick={() => clickAction(item.id)} key={`bookmark_collection_${key}`} className='px-2 w-full h-[100%]'>
                  <div>
                    <div className='mb-3 border border-gray-200 px-4 py-4 rounded-xl group hover:shadow-[0_9px_15px_0px_rgba(0,0,0,0.1)] hover:border-[rgba(202,138,4,0.25)] dark:border-gray-600 dark:hover:border-[rgba(202,138,4,0.25)] flex center items-center cursor-pointer dark:bg-gray-800'>
                      <div className='w-[20%]'>
                        <span className='inline-block w-[48px] text-center py-2 md:py-3 rounded-xl shadow group-hover:bg-yellow-400 group-hover:text-white dark:text-gray-400 dark:shadow-gray-600'>
                          {key + 1}
                        </span>
                      </div>
                      <div className='w-[80%] text-start'>
                        <div className='ml-2'>
                          <p className='group-hover:text-yellow-400 dark:text-gray-200'>{item.title}</p>
                          <p className='text-sm dark:text-gray-400'>
                            {item?.ayats?.length} {t('ayat.label')}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <p className='my-8 text-center dark:text-gray-300'>{t('bookmark.notFoundCollection')}</p>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default AddToBookmarkModal;
