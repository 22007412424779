import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { IAppContextState, AppContext } from '../../../App';

import MainLayout from '../../../layout/MainLayout';

import { IAyat } from '../../home/interface/surah.interface';
import { IBookmark } from '../interface/bookmark.interface';

import { StorageService } from '../../../shared/service/storageService';
import { toast } from 'react-toastify';

function BookmarkDetails() {
  const { t } = useTranslation();
  const { categoryId } = useParams();

  const storageService = new StorageService();

  const { bookmarkState } = useContext<IAppContextState | any>(AppContext);

  const [bookmarks, setBookmarks] = bookmarkState;

  const [bookmarkedAyat, setBookmarkedAyat] = useState<IAyat[]>([]);

  const removeAyat = (ayatCode: string) => {
    const afterRemovedAyatFromBookmark = storageService.removeAyatFromBookmark(ayatCode);
    setBookmarks(afterRemovedAyatFromBookmark);
    storageService.setBookmarks(afterRemovedAyatFromBookmark);

    let localAyats = storageService.getBookmarkedAyat();
    localAyats.splice(localAyats.indexOf(ayatCode), 1);
    storageService.setBookmarkedAyat(localAyats);
    loadAyats();
    toast.success(`${t('bookmark.messages.ayat.delete')}`);
  };

  const loadAyats = () => {
    const category: IBookmark = storageService?.getBookmarks()?.find((bookmark: IBookmark) => bookmark.id === categoryId) as IBookmark;
    setBookmarkedAyat(category?.ayats ?? []);
  };

  useEffect(() => {
    loadAyats();
  }, [categoryId]);

  return (
    <MainLayout>
      <h1 className='border-b-2 border-yellow-400 border-opacity-10 text-center font-normal text-[2rem] py-8 dark:text-yellow-400'>{t('bookmark.title')}</h1>
      {bookmarkedAyat?.length ? (
        bookmarkedAyat.map((item: IAyat, key: number) => (
          <div id={`ayat_${item.id}`} key={`ayat_list_${key}`} className='border-b border-gray-300 border-dashed dark:border-gray-500 last:border-0'>
            <div className='lg:flex justify-between items-end mb-8 mt-6'>
              <div className='w-[95%]'>
                <p className='font-arabic flex justify-end items-end text-[1.5rem] mb-8'>
                  {/* <div className='mb-3'>
                    <span className='block ayat-no mr-3 dark:text-gray-300'>{item.id}</span>
                  </div> */}
                </p>
                <div className='text-start'>
                  <p className='dark:text-gray-300'>{item?.translation?.ayat_translation ?? ''}</p>
                  {item?.translator_name ? (
                    <small className='text-gray-400 mt-4 inline-block'>
                      Translated by : <span className='text-yellow-400'>{item?.translator_name}</span>
                    </small>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
              <ul className='w-[5%] flex lg:block mb-4 lg:mb-0'>
                <li className='lg:mb-3 me-2'>
                  <button onClick={() => removeAyat(item.ayat_code)} className='group bg-red-500 hover:bg-red-400 rounded-full w-[34px] h-[34px] shadow dark:shadow-yellow-400'>
                    <i className='ri-delete-bin-line text-white text-lg'></i>
                  </button>
                </li>
              </ul>
            </div>
          </div>
        ))
      ) : (
        <p className='my-8 text-center dark:text-gray-300'>{t('bookmark.notFound')}</p>
      )}
    </MainLayout>
  );
}

export default BookmarkDetails;
