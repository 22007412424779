import { Link } from 'react-router-dom';
import MainLayout from '../../layout/MainLayout';

function PrivacyPolicy() {
  return (
    <MainLayout>
      <div>
        <h1 className='text-center font-bold text-[1.5rem] py-8 border-b-2 border-yellow-400 border-opacity-10 dark:text-yellow-400'>Privacy Policy</h1>
        <div className='mt-3'>
          <p className='dark:text-gray-300'>Last updated: April 1, 2024</p>
          <p className='dark:text-gray-400'>
            This Privacy Policy governs the manner in which QuranRT collects, uses, maintains, and discloses information collected from users (each, a "User") of the QuranRT mobile application
            ("App"). This privacy policy applies to the App and all products and services offered by QuranRT.
          </p>
        </div>
        <div className='mt-3'>
          <h4 className='font-bold text-[1rem] pt-4 mb-0 dark:text-yellow-300'>Information We Collect</h4>
          <p className=' dark:text-gray-300'>
            QuranRT does not collect any personal identification information from Users. We only require storage permission to save downloaded books and allow users to read them within the App.
          </p>
        </div>
        <div className='mt-3'>
          <h4 className='font-bold text-[1rem] pt-4 mb-0 dark:text-yellow-300'>Non-personal identification information</h4>
          <p className=' dark:text-gray-300'>
            We may collect non-personal identification information about Users whenever they interact with our App. Non-personal identification information may include the type of device and technical
            information about Users' means of connection to our App, such as the operating system and the Internet service providers utilized, but this information is collected anonymously and cannot
            be used to identify individual users.
          </p>
        </div>
        <div className='mt-3'>
          <h4 className='font-bold text-[1rem] pt-4 mb-0 dark:text-yellow-300'>How We Use Collected Information</h4>
          <p className=' dark:text-gray-300'>
            QuranRT does not collect any personal information from Users. The storage permission is only used to save downloaded books and allow users to read them within the App. We do not use any
            collected information for any other purpose.
          </p>
        </div>
        <div className='mt-3'>
          <h4 className='font-bold text-[1rem] pt-4 mb-0 dark:text-yellow-300'>How We Protect Your Information</h4>
          <p className=' dark:text-gray-300'>
            We adopt appropriate data collection, storage, and processing practices and security measures to protect against unauthorized access, alteration, disclosure, or destruction of any
            non-personal information collected by the App.
          </p>
        </div>
        <div className='mt-3'>
          <h4 className='font-bold text-[1rem] pt-4 mb-0 dark:text-yellow-300'>Sharing Your Information</h4>
          <p className=' dark:text-gray-300'>
            We do not share any information collected by the App with third parties. All non-personal information collected is used solely for the purpose of providing and improving the services
            offered by QuranRT.
          </p>
        </div>
        <div className='mt-3'>
          <h4 className='font-bold text-[1rem] pt-4 mb-0 dark:text-yellow-300'>Changes to This Privacy Policy</h4>
          <p className=' dark:text-gray-300'>
            QuranRT has the discretion to update this privacy policy at any time. When we do, we will revise the updated date at the bottom of this page. We encourage Users to frequently check this
            page for any changes to stay informed about how we are protecting the information collected by the App.
          </p>
        </div>
        <div className='mt-3'>
          <h4 className='font-bold text-[1rem] pt-4 mb-0 dark:text-yellow-300'>Your Acceptance of These Terms</h4>
          <p className='mb-2 dark:text-gray-300'>
            By using this App, you signify your acceptance of this policy. If you do not agree to this policy, please do not use our App. Your continued use of the App following the posting of changes
            to this policy will be deemed your acceptance of those changes.
          </p>
          <p className='mb-2 dark:text-gray-300'>Contacting Us</p>
          <p className='mb-2 dark:text-gray-300'>If you have any questions about this Privacy Policy or the practices of this App, please contact us at:</p>
          <p className='mb-2 dark:text-gray-300'>
            QuranRT <br />{' '}
            <Link to={'https://api.quranrt.org'}>
              <a className='underline text-blue-500 dark:text-yellow-500'>https://api.quranrt.org</a>
            </Link>
          </p>
          <p className=' dark:text-gray-300'>This document was last updated on April 1, 2024.</p>
        </div>
      </div>
    </MainLayout>
  );
}

export default PrivacyPolicy;
