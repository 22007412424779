'use client';

import { Modal } from 'flowbite-react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

interface ICreateBookmarkModalProps {
  state: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
  clickAction: (title: string) => void;
}

function CreateBookmarkCategoryModal({ state, clickAction }: ICreateBookmarkModalProps) {
  const { t } = useTranslation();
  const [openModal, setOpenModal] = state;

  const [categoryTitleInput, setCategoryTitleInput] = useState('');

  const handleClickAction = () => {
    if (categoryTitleInput !== '') {
      clickAction(categoryTitleInput);
      setOpenModal(false);
      setCategoryTitleInput('');
    }
  };

  return (
    <>
      <Modal show={openModal} size='md' onClose={() => setOpenModal(false)} popup>
        <Modal.Header>
          <h4 className='p-3'>{t('bookmark.addCollection')}</h4>
        </Modal.Header>
        <Modal.Body>
          <div className='text-center'>
            <div className='my-3'>
              <input
                value={categoryTitleInput}
                onChange={(e) => setCategoryTitleInput(e.target.value)}
                type='text'
                id='create_bookmark_input_field'
                className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-yellow-400 focus:border-yellow-400 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
                placeholder={t('placeholder.title')}
                required
              />
            </div>

            <button className='bg-yellow-400 block text-white w-full rounded-full py-3' onClick={() => handleClickAction()}>
              {t('action.save')}
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default CreateBookmarkCategoryModal;
