import { INote } from '../../modules/home/interface/note.interface';
import { IBookmark } from '../../modules/bookmark/interface/bookmark.interface';

import { ELanguageCode } from '../enum/language.enum';

export enum EStorageKeys {
  DARK_MODE = 'quran_rt_dark_mode',
  LANG_CODE = 'quran_rt_lang_code',
  BOOKMARK = 'quran_rt_bookmark',
  BOOKMARKED_AYAT = 'quran_rt_bookmarked_ayat',
  HIGHLIGHT = 'quran_rt_highlight',
  IS_OPEN_SIDEBAR = 'quran_rt_is_open_sidebar',
  IS_COLLAPSE_SIDEBAR = 'quran_rt_is_collapse_sidebar',
  IS_READING_MODE = 'quran_rt_is_reading_code',
  NOTE = 'quran_rt_note',
}

export class StorageService {
  getNotes(): INote[] {
    const notes = localStorage.getItem(EStorageKeys.NOTE);
    return notes ? JSON.parse(notes) : [];
  }
  setNotes(notes: INote[]) {
    localStorage.setItem(EStorageKeys.NOTE, JSON.stringify(notes));
  }

  getHighlights(): string[] {
    const highlights = localStorage.getItem(EStorageKeys.HIGHLIGHT);
    return highlights ? JSON.parse(highlights) : [];
  }
  setHighlights(highlights: string[]) {
    localStorage.setItem(EStorageKeys.HIGHLIGHT, JSON.stringify(highlights));
  }

  getBookmarkedAyat(): string[] {
    const ayats = localStorage.getItem(EStorageKeys.BOOKMARKED_AYAT);
    return ayats ? JSON.parse(ayats) : [];
  }

  setBookmarkedAyat(ayat: string[]) {
    localStorage.setItem(EStorageKeys.BOOKMARKED_AYAT, JSON.stringify(ayat));
  }

  removeAyatFromBookmark(ayatCode: string) {
    let localBookmarks = this.getBookmarks();
    return localBookmarks.map((bookmark: IBookmark) => {
      const ayats = bookmark?.ayats?.filter((ayat) => ayat.ayat_code !== ayatCode) ?? [];
      bookmark.ayats = ayats;
      return bookmark;
    });
  }

  getBookmarks(): IBookmark[] {
    const bookmarks = localStorage.getItem(EStorageKeys.BOOKMARK);
    return bookmarks ? JSON.parse(bookmarks) : [];
  }
  setBookmarks(bookmarks: IBookmark[]) {
    localStorage.setItem(EStorageKeys.BOOKMARK, JSON.stringify(bookmarks));
  }

  getIsReadingMode() {
    return localStorage.getItem(EStorageKeys.IS_READING_MODE) === 'true' ? true : false;
  }
  setIsReadingMode(isReadingMode: boolean) {
    localStorage.setItem(EStorageKeys.IS_READING_MODE, isReadingMode ? 'true' : 'false');
  }

  getIsCollapseSidebar() {
    return localStorage.getItem(EStorageKeys.IS_COLLAPSE_SIDEBAR) === 'true' ? true : false;
  }
  setIsCollapseSidebar(isCollapseSidebar: boolean) {
    localStorage.setItem(EStorageKeys.IS_COLLAPSE_SIDEBAR, isCollapseSidebar ? 'true' : 'false');
  }

  getIsOpenSidebar() {
    return localStorage.getItem(EStorageKeys.IS_OPEN_SIDEBAR) === 'true' ? true : false;
  }
  setIsOpenSidebar(isOpenSidebar: boolean) {
    localStorage.setItem(EStorageKeys.IS_OPEN_SIDEBAR, isOpenSidebar ? 'true' : 'false');
  }

  getIsDarkModeActivated() {
    return localStorage.getItem(EStorageKeys.DARK_MODE) === 'true' ? true : false;
  }
  setIsDarkModeActivated(isActive: boolean) {
    localStorage.setItem(EStorageKeys.DARK_MODE, isActive ? 'true' : 'false');
  }

  getLanguageCode(): any {
    return localStorage.getItem(EStorageKeys.LANG_CODE);
  }
  setLanguageCode(langCode: ELanguageCode) {
    localStorage.setItem(EStorageKeys.LANG_CODE, langCode);
  }
}
