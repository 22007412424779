import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import MainLayout from '../../../layout/MainLayout';

import { IAppContextState, AppContext } from '../../../App';

import { StorageService } from '../../../shared/service/storageService';

import { INote } from '../../home/interface/note.interface';
import { toast } from 'react-toastify';

function Note() {
  const { t } = useTranslation();
  const { noteState } = useContext<IAppContextState | any>(AppContext);
  const [notes, setNotes] = noteState;

  const storageService = new StorageService();

  const addOrRemoveNote = (ayatCode: string) => {
    let localNotes = storageService.getNotes();
    const afterRemovedNotes = localNotes?.filter((note: INote) => note.ayat_code !== ayatCode);
    storageService.setNotes(afterRemovedNotes);
    setNotes(afterRemovedNotes);
    toast.error(`${t('note.messages.deleteNote')}`);
  };

  useEffect(() => {
    setNotes(storageService.getNotes() ?? []);
  }, []);
  return (
    <MainLayout>
      <div>
        <h1 className='text-center font-normal text-[2rem] py-8 border-b-2 border-yellow-400 border-opacity-10 dark:text-yellow-400'>{t('note.title')}</h1>
        <div className='my-12'>
          {notes?.length ? (
            notes?.map((note: INote, key: number) => (
              <span
                id={`note_${key}`}
                key={`note_${key}`}
                className='inline-flex items-center px-2 py-1 me-2 text-sm font-medium text-yellow-800 bg-yellow-100 rounded dark:bg-yellow-900 dark:text-yellow-300'>
                {note?.text}
                <button
                  onClick={() => addOrRemoveNote(note.ayat_code)}
                  type='button'
                  className='inline-flex items-center p-1 ms-2 text-sm text-yellow-400 bg-transparent rounded-sm hover:bg-yellow-200 hover:text-yellow-900 dark:hover:bg-yellow-500 dark:hover:text-yellow-300'
                  data-dismiss-target='#badge-dismiss-yellow'
                  aria-label='Remove'>
                  <svg className='w-2 h-2' aria-hidden='true' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 14 14'>
                    <path stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6' />
                  </svg>
                  <span className='sr-only'>Remove badge</span>
                </button>
              </span>
            ))
          ) : (
            <p className='text-center dark:text-white'>{t('note.notFound')}</p>
          )}
        </div>
      </div>
    </MainLayout>
  );
}

export default Note;
