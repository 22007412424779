'use client';

import { Button, Modal } from 'flowbite-react';
import { useTranslation } from 'react-i18next';
import { HiOutlineExclamationCircle } from 'react-icons/hi';

interface IConfirmModalProps {
  state: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
  clickAction: (props: any) => void;
  data?: any;
  text?: string;
}

function ConfirmModal({ state, text, clickAction, data }: IConfirmModalProps) {
  const { t } = useTranslation();
  const [openModal, setOpenModal] = state;

  const handleClickAction = () => {
    clickAction(data);
    setOpenModal(false);
  };

  return (
    <>
      <Modal show={openModal} size='md' onClose={() => setOpenModal(false)} popup>
        <Modal.Header />
        <Modal.Body>
          <div className='text-center'>
            <HiOutlineExclamationCircle className='mx-auto mb-4 h-14 w-14 text-gray-400 dark:text-gray-200' />
            <h3 className='mb-5 text-lg font-normal text-gray-500 dark:text-gray-400'>{text}</h3>
            <div className='flex justify-center gap-4'>
              <Button color='failure' onClick={() => handleClickAction()}>
                {t('action.yes')}
              </Button>
              <Button color='gray' onClick={() => setOpenModal(false)}>
                {t('action.no')}
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ConfirmModal;
