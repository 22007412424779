import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { Dropdown } from 'flowbite-react';

import { IAppContextState, AppContext } from '../../../App';

import MainLayout from '../../../layout/MainLayout';

import { IAyat, ISurahDetails } from '../interface/surah.interface';
import { IBookmark } from '../../bookmark/interface/bookmark.interface';

import { SurahService } from '../service/SurahService';
import { StorageService } from '../../../shared/service/storageService';

import Header from '../../../shared/components/Header';
import SurahDetailsSkeleton from '../skeleton/SurahDetailsSkeleton';
import AddToBookmarkModal from '../../bookmark/components/AddToBookmarkModal';

import { flowbiteCustomDropdownThemeConfig } from '../../../shared/config/flowbiteCustomThemeConfig';
import AddNoteModal from './AddNoteModal';
import { INote } from '../interface/note.interface';

function AyatDetails() {
  const { t } = useTranslation();
  const surahDetailsDefaultValue = {
    total_counts: 0,
    surah: null,
    ayah: [],
  };
  const { langCodeState, bookmarkState, highlightState, addOrRemoveHighlight, isReadingModeState, noteState } = useContext<IAppContextState | any>(AppContext);

  const { id } = useParams();

  const [langCode] = langCodeState;
  const [bookmarks, setBookmarks] = bookmarkState;
  const [highlights] = highlightState;
  const [isReadingMode] = isReadingModeState;
  const [notes, setNotes] = noteState;

  const [loading, setLoading] = useState(false);
  const [markedBookmarkAyat, setMarkedBookmarkAyat] = useState<IAyat | null>(null);
  const [openAddBookmarkModal, setOpenAddBookmarkModal] = useState(false);
  const [openAddNoteModal, setOpenAddNoteModal] = useState(false);
  const [addNoteModalData, setAddNoteModalData] = useState<any>(null);
  const [surahDetails, setSurahDetails] = useState<ISurahDetails>(surahDetailsDefaultValue);

  const surahService = new SurahService();
  const storageService = new StorageService();

  const [bookmarkedAyatCodes, setBookmarkedAyatCodes] = useState<string[]>(storageService.getBookmarkedAyat() ?? []);

  const copyText = (text: string) => {
    navigator.clipboard.writeText(text);
    toast.success('Text copied');
  };

  const handleOpenAddNoteModal = (ayat: IAyat) => {
    setAddNoteModalData(ayat);
    setOpenAddNoteModal(true);
  };

  const handleOpenAddBookmarkModal = (ayat: IAyat) => {
    if (bookmarkedAyatCodes.includes(ayat.ayat_code)) {
      removeAyat(ayat.ayat_code);
    } else {
      setMarkedBookmarkAyat(ayat);
      setOpenAddBookmarkModal(true);
    }
  };

  const removeAyat = (ayatCode: string) => {
    const afterRemovedAyatFromBookmark = storageService.removeAyatFromBookmark(ayatCode);
    setBookmarks(afterRemovedAyatFromBookmark);
    storageService.setBookmarks(afterRemovedAyatFromBookmark);

    let localAyats = storageService.getBookmarkedAyat();
    localAyats.splice(localAyats.indexOf(ayatCode), 1);
    storageService.setBookmarkedAyat(localAyats);
    setBookmarkedAyatCodes(localAyats);

    toast.success(`${t('bookmark.messages.ayat.delete')}`);
  };

  const addToBookmark = (collectionId: string) => {
    let localBookmarks = storageService.getBookmarks();
    let index = localBookmarks?.findIndex((bookmark: IBookmark) => bookmark.id === collectionId);
    if (index !== undefined) {
      if (markedBookmarkAyat) {
        let localBookmarkedAyats = storageService.getBookmarkedAyat();

        localBookmarks?.[index]?.ayats.push(markedBookmarkAyat);
        localBookmarkedAyats.push(markedBookmarkAyat.ayat_code);

        storageService.setBookmarks(localBookmarks);
        storageService.setBookmarkedAyat(localBookmarkedAyats);

        setBookmarks(localBookmarks);
        setBookmarkedAyatCodes(localBookmarkedAyats);

        setMarkedBookmarkAyat(null);
        setOpenAddBookmarkModal(false);

        toast.success(`${t('bookmark.messages.ayat.add')}`);
      }
    }
  };

  const getSurahDetails = async (id: number) => {
    try {
      setLoading(true);
      const surahDetailsResponse = await surahService.getOneSurah(id, { lang_code: langCode });
      setSurahDetails(surahDetailsResponse?.data ?? []);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    setNotes(storageService.getNotes() ?? []);

    return () => {
      setNotes([]);
    };
  }, []);

  useEffect(() => {
    if (id) {
      getSurahDetails(Number(id));
    }
    return () => {
      setSurahDetails(surahDetailsDefaultValue);
      setMarkedBookmarkAyat(null);
      setAddNoteModalData(null);
    };
  }, [id, langCode]);

  const copyDropdownLabel: HTMLElement | any = (
    <button className='group hover:bg-gold rounded-full w-[34px] h-[34px] shadow dark:shadow-yellow-400'>
      <i className='ri-file-copy-fill group-hover:text-black text-gray-500 text-lg dark:text-gray-400'></i>
    </button>
  );

  const moreDropdownLabel: HTMLElement | any = (
    <button className='group hover:bg-gold rounded-full w-[34px] h-[34px] shadow dark:shadow-yellow-400'>
      <i className='ri-more-fill group-hover:text-black text-gray-500 text-lg  dark:text-gray-400'></i>
    </button>
  );

  return (
    <MainLayout>
      <AddNoteModal data={addNoteModalData} state={[openAddNoteModal, setOpenAddNoteModal]} />
      <AddToBookmarkModal
        markedBookmarkAyat={markedBookmarkAyat}
        clickAction={(collectionId: string) => {
          addToBookmark(collectionId);
        }}
        state={[openAddBookmarkModal, setOpenAddBookmarkModal]}
      />
      <Header>
        {loading ? (
          <SurahDetailsSkeleton />
        ) : (
          <div className='mx-auto px-3 md:max-w-[600] xl:max-w-[1100px] 2xl:max-w-[1400px]'>
            <div className='text-center pt-12 pb-16'>
              <p className='text-[3rem] font-arabic font-bold dark:text-gray-200'>{surahDetails?.surah?.surah_name_arabic}</p>
              <p className='text-[1.15rem] dark:text-gray-300'>{surahDetails?.surah?.title_translation}</p>
              {Number(id) !== 1 && Number(id) !== 9 && <p className='text-[2rem] dark:text-gray-300 font-arabic mt-3'>بِسۡمِ اللّٰہِ الرَّحۡمٰنِ الرَّحِیۡمِ</p>}
            </div>
            {surahDetails?.ayah?.map((item: IAyat, key: number) =>
              isReadingMode ? (
                <div id={`ayat_${surahDetails.surah?.id}_${item.ayat_code}`} key={`ayat_list_${key}`}>
                  <p className='font-arabic flex justify-center items-center text-[1.5rem] mb-8'>
                    <div className='text-center flex flex-wrap flex-row-reverse'>
                      {item.ayat.split(' ').map((word, index) => (
                        <span
                          key={`arabic_word_${item.id}_${index}`}
                          onClick={() => addOrRemoveHighlight(word)}
                          className={`${highlights.includes(word) && 'bg-yellow-400 text-white font-bold'} dark:text-gray-300 mx-2 cursor-pointer hover:text-yellow-400 text-[2rem] arabic-bengali`}>
                          {word}
                        </span>
                      ))}
                    </div>
                  </p>
                </div>
              ) : (
                <div id={`ayat_${key + 1}`} key={`ayat_list_${key}`} className='border-b border-gray-300 border-dashed dark:border-gray-500 last:border-0'>
                  <div className='lg:flex justify-between mb-8 mt-6'>
                    <ul className='w-[5%] flex lg:block mb-4 lg:mb-0'>
                      <li className='lg:mb-3 me-2 mt-1'>
                        <span className='dark:text-gray-400 dark:bg-gray-600 px-2 py-1 rounded-lg dark:font-bold text-xs text-nowrap'>
                          <span className='dark:font-bold'>{item.ayat_code}</span>
                        </span>
                      </li>
                      <li className='lg:mb-3 me-2'>
                        <button className='group hover:bg-gold dark:hover:bg-yellow-400 rounded-full w-[34px] h-[34px] shadow dark:shadow-yellow-400'>
                          <i className='ri-play-fill group-hover:text-black text-gray-500 text-lg dark:text-gray-400'></i>
                        </button>
                      </li>
                      <li className='lg:mb-3 me-2'>
                        <button
                          onClick={() => handleOpenAddBookmarkModal(item)}
                          className={`${
                            bookmarkedAyatCodes.includes(item.ayat_code) ? 'bg-green-500 hover:bg-green-600' : 'hover:bg-gold dark:hover:bg-yellow-400 dark:shadow-yellow-400'
                          } group rounded-full w-[34px] h-[34px] shadow`}>
                          <i className={`${bookmarkedAyatCodes.includes(item.ayat_code) ? 'text-white' : 'text-gray-500 dark:text-gray-400 group-hover:text-black'} ri-bookmark-fill text-lg`}></i>
                        </button>
                      </li>
                      <li className='lg:mb-3 me-2'>
                        <button
                          onClick={() => handleOpenAddNoteModal(item)}
                          className={`${
                            notes.find((note: INote) => note.ayat_code === item.ayat_code) ? 'bg-yellow-400 hover:bg-yellow-500' : 'hover:bg-gold dark:hover:bg-yellow-400 dark:shadow-yellow-400'
                          } group rounded-full w-[34px] h-[34px] shadow`}>
                          <i
                            className={`${
                              notes.find((note: INote) => note.ayat_code === item.ayat_code) ? 'text-white' : 'text-gray-500 dark:text-gray-400 group-hover:text-black'
                            } ri-sticky-note-add-fill text-lg`}></i>
                        </button>
                      </li>
                      <li className='lg:mb-3 me-2'>
                        <Dropdown label='' renderTrigger={() => copyDropdownLabel} theme={flowbiteCustomDropdownThemeConfig}>
                          <Dropdown.Item
                            onClick={() =>
                              copyText(`
${item.ayat}
${item.translation.ayat_translation}`)
                            }>
                            Copy All
                          </Dropdown.Item>
                          <Dropdown.Item onClick={() => copyText(item.ayat)}>Copy Arabic</Dropdown.Item>
                          <Dropdown.Item onClick={() => copyText(item.translation.ayat_translation)}>Copy Translation</Dropdown.Item>
                        </Dropdown>
                      </li>
                      {/* <li>
                      <Dropdown label='' renderTrigger={() => moreDropdownLabel} theme={flowbiteCustomDropdownThemeConfig}>
                        <Dropdown.Item>
                          <i className='ri-link me-2'></i>Copy Link
                        </Dropdown.Item>
                        <Dropdown.Item>
                          <i className='ri-share-line me-2'></i> Share
                        </Dropdown.Item>
                      </Dropdown>
                    </li> */}
                    </ul>
                    <div className='w-[95%]'>
                      <p className='font-arabic flex justify-end items-end text-[1.5rem] mb-8'>
                        <div className='text-end flex flex-wrap flex-row-reverse'>
                          {item.ayat.split(' ').map((word, index) => (
                            <span
                              key={`arabic_word_${item.id}_${index}`}
                              onClick={() => addOrRemoveHighlight(word)}
                              className={`${highlights.includes(word) && 'bg-yellow-400 text-white font-bold'} dark:text-gray-300 mx-2 cursor-pointer hover:text-yellow-400 text-[2rem]`}>
                              {word}
                            </span>
                            // <Tooltip
                            //   key={index}
                            //   theme={flowbiteCustomTooltipThemeConfig}
                            //   content={item?.translation?.ayat_translation?.split(' ')[item?.translation?.ayat_translation?.split(' ')?.length - 1 - index]}>
                            //   <span className='hover:text-yellow-400'>{word}</span>
                            // </Tooltip>
                          ))}
                        </div>
                      </p>
                      <div className='text-start'>
                        <p className='dark:text-gray-300 flex flex-wrap'>
                          {item?.translation?.ayat_translation?.split(' ').map((word, index) => (
                            <span
                              key={`ayat_translation_word_${item.id}_${index}`}
                              onClick={() => addOrRemoveHighlight(word)}
                              className={`${highlights.includes(word) && 'bg-yellow-400 text-white font-bold'} mx-1 cursor-pointer`}>
                              {word}
                            </span>
                          )) ?? ''}
                        </p>
                        {item?.translator_name ? (
                          <small className='text-gray-400'>
                            translated by : <span className='text-yellow-400'>{item?.translator_name}</span>
                          </small>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )
            )}
          </div>
        )}
      </Header>
    </MainLayout>
  );
}

export default AyatDetails;
