import { useContext } from 'react';
import { Link } from 'react-router-dom';

import MainLayout from '../../../layout/MainLayout';

import { IAppContextState, AppContext } from '../../../App';

import { ISurah } from '../interface/surah.interface';

import Header from '../../../shared/components/Header';
import SurahListSkeleton from '../skeleton/SurahListSkeleton';

function SurahList() {
  const { surahState, loadingSurahState } = useContext<IAppContextState | any>(AppContext);

  const [surah] = surahState;
  const [loadingSurah] = loadingSurahState;

  return (
    <MainLayout>
      <Header>
        {loadingSurah ? (
          <SurahListSkeleton />
        ) : (
          <div className='surah-container flex flex-wrap'>
            {surah?.surahs?.map((surah: ISurah, key: number) => (
              <div key={`surah_list_${key}`} className='px-2 xl:w-[33.33%] lg:w-[50%] md:w-[50%] w-[100%] h-[100%]'>
                <Link to={`/surah/${surah.sl_no}`}>
                  <div
                    key={surah.id}
                    className='mb-3 border border-gray-200 py-8 rounded-xl group hover:shadow-[0_9px_15px_0px_rgba(0,0,0,0.1)] hover:border-[rgba(202,138,4,0.25)] dark:border-gray-600 dark:hover:border-[rgba(202,138,4,0.25)] flex justify-center items-center cursor-pointer dark:bg-gray-800'>
                    <div className='w-[20%]'>
                      <span className='inline-block w-[48px] text-center py-2 md:py-3 rounded-xl shadow group-hover:bg-yellow-400 group-hover:text-white dark:text-gray-400 dark:shadow-gray-600'>
                        {surah.sl_no}
                      </span>
                    </div>
                    <div className='w-[50%]'>
                      <p className='group-hover:text-yellow-400 dark:text-gray-200'>{surah.title_translation}</p>
                      <p className='text-sm dark:text-gray-400'>{surah?.translation}</p>
                    </div>
                    <div className='w-[20%] text-end'>
                      <p className='font-arabic text-sm dark:text-gray-200'>{surah?.surah_name_arabic}</p>
                      <p className='text-xs dark:text-gray-400'>{surah?.total_ayat} ayat</p>
                    </div>
                  </div>
                </Link>
              </div>
            ))}
          </div>
        )}
      </Header>
    </MainLayout>
  );
}

export default SurahList;
